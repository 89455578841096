* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;

}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  background: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 2000px rgb(53, 57, 53), 0.7;


}

.custom_navbar {
  background-color: #28282B !important;
}

.container {
  width: 100%;

}

.navbar_ul li a {
  color: white !important;
}

.main {
  background-color: #353935;
  height: 70vh;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.services {
  background-color: #28282B;
  padding: 20px;
  border-radius: 12px;
  width: 40%;
  height: 50vh;
}

.contact {
  background-color: #28282B;
  padding: 20px;
  border-radius: 12px;
  width: 40%;
  height: 50vh;
}

.faq {
  background-color: #353935;
  color: black;
  padding: 5px 10px;
  border-radius: 10px;

}


.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;

}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: #28282B;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.main_pp {
  height: 100%;


}

.main_pp h1 {
  color: gold;
  margin: 20px 0;
}

.pp {
  background-color: #353935;
  color: black;
  /* padding: 5px 10px; */
  color: white;
  border-radius: 10px;
  padding: 20px;


}

.footer {
  background-color: #353935;
  margin-top: 35px;
  padding: 10px;
  text-align: center;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.link_white {
  color: white !important;
}

@media(max-width: 800px) {
  .services {
    width: 80%;
    flex-wrap: wrap;
    margin: 10px;
  }


  .contact {
    width: 80%;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .main {
    height: auto;
  }

  .pp {
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: auto;
  }
}

@media(max-width: 500px) {

  .contact {
    height: auto;

  }

  .container {
    width: 50%;
  }

  .main_pp {

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
}